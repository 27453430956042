import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Para redirigir y obtener los parámetros de la URL
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function Aprobado() {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false); // Para mostrar el mensaje de éxito

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const collectionStatus = searchParams.get('collection_status');

    if (collectionStatus !== 'approved') {
      // Si el estado de la transacción no es "approved", redirigir a otra página
      // console.log('Intento de acceso no autorizado a la página de Aprobado.');
      navigate('/');
      return;
    }

    const enviarDatosAlBackend = async (url, paymentData) => {
      const productos = paymentData.productos.map(producto => {
        let costeAdicionalModificado = producto.coste_adicional;

        if (producto.cantidad === 1 && producto.coste_adicional > 0) {
          costeAdicionalModificado = 0;
        } else if (producto.cantidad >= 2 && producto.coste_adicional > 0) {
          costeAdicionalModificado = (producto.cantidad - 1) * 2000;
        }

        return {
          id_producto: producto.id,
          titulo: producto.titulo,
          precio: producto.precio,
          cantidad: producto.cantidad,
          coste_adicional: costeAdicionalModificado
        };
      });

      const totalCosteAdicional = productos.reduce((total, producto) => total + producto.coste_adicional, 0);
      const totalAPagar = parseInt(paymentData.precio_total, 10) + parseInt(paymentData.precio_comuna, 10) + totalCosteAdicional;

      // console.log('Datos para enviar:', {
      //   ...paymentData,
      //   productos: productos,
      //   total_pagar: totalAPagar,
      // });

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            nombre: paymentData.nombre,
            rut: paymentData.rut,
            telefono: paymentData.telefono,
            correo: paymentData.correo,
            precio_total: paymentData.precio_total,
            precio_comuna: paymentData.precio_comuna,
            total_pagar: totalAPagar,
            medio_pago: paymentData.medioPago,
            productos: JSON.stringify(productos),
            update_stock: true,
            direccion: paymentData.direccion,
            comuna: paymentData.comuna,
            region: paymentData.region.nombre
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error(`Error en la solicitud a ${url}:`, response.statusText, errorText);
          throw new Error('Error en la solicitud: ' + response.statusText);
        }

        const result = await response.json();
        if (result.success) {
          // console.log(`Datos actualizados correctamente en ${url}`);
          setSuccess(true); // Indicar que todo salió bien
        } else {
          console.error(`Error al actualizar datos en ${url}:`, result.error || 'Error desconocido');
        }
      } catch (error) {
        console.error(`Error en la solicitud a ${url}:`, error.message);
      }
    };

    // Obtener los datos del localStorage
    const paymentData = localStorage.getItem('paymentData');

    if (paymentData) {
      const parsedData = JSON.parse(paymentData);
      // console.log('Datos de pago:', parsedData);

      // Enviar datos al backend
      enviarDatosAlBackend('https://pruebas.onloop.cl/api/actualizar_compra.php', parsedData);

      const comprobanteUrl = parsedData.region.nombre === 'Metropolitana de Santiago'
        ? 'https://pruebas.onloop.cl/api/comprobante_completo.php'
        : 'https://pruebas.onloop.cl/api/comprobante_incompleto.php';

      enviarDatosAlBackend(comprobanteUrl, parsedData);

      // Eliminar los datos del localStorage después de usarlos
      localStorage.removeItem('paymentData');

      // Redirigir a la página principal después de 5 segundos
      setTimeout(() => {
        navigate('/');
      }, 5000); // 5000 ms = 5 segundos
    } else {
      // console.log('No hay datos de pago en localStorage.');
      
      // Redirigir a la página principal después de 3 segundos
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }, [location, navigate]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f4f4f4'
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '30px',
          borderRadius: '10px',
          textAlign: 'center',
          maxWidth: '400px',
          backgroundColor: '#fff'
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          ¡Compra Exitosa!
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          Su compra ha sido procesada exitosamente.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          En breve recibirá un comprobante en su correo.
        </Typography>
        {success && (
          <Typography variant="body1" component="p" sx={{ mt: 2, color: 'green' }}>
            Regresaras a la página de inicio en unos momentos...
          </Typography>
        )}
      </Paper>
    </Box>
  );
}

export default Aprobado;
