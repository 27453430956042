import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../../Context/CardContext';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ConfirmarProductos from './ConfirmarProductos';
import DatosEntrega from './DatosEntrega';
import Resumen from './Resumen';
import SeleccionMedioPago from './SeleccionMedioPago';
import axios from 'axios';

const steps = [
    <Typography 
      key={0} 
      sx={{ 
        fontFamily: 'Outfit, sans-serif', 
        fontWeight: 700, 
        textAlign: 'justify', 
        textJustify: 'inter-word' 
      }}
    >
      Confirmar productos
    </Typography>,
    <Typography 
      key={1} 
      sx={{ 
        fontFamily: 'Outfit, sans-serif', 
        fontWeight: 700, 
        textAlign: 'justify', 
        textJustify: 'inter-word',
      }}
    >
      Datos de entrega
    </Typography>,
    <Typography 
      key={2} 
      sx={{ 
        fontFamily: 'Outfit, sans-serif', 
        fontWeight: 700, 
        textAlign: 'justify', 
        textJustify: 'inter-word' 
      }}
    >
      Resumen
    </Typography>
  ];
  
const ConfirmacionCompra = () => {
    const { carritoFiltrado, precioTotal, eliminarProducto, vaciarCarrito } = useContext(CartContext);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [comunaData, setComunaData] = useState({ selectedRegion: {}, selectedComuna: '', costoEnvio: null }); 
    const [requestSent, setRequestSent] = useState(false);
    const [regiones, setRegiones] = useState([]);
    const [mediosPago, setMediosPago] = useState([]);
    const [medioPago, setMedioPago] = useState('');
    const [direccion, setDireccion] = useState('');
    const [correo, setCorreo] = useState('');
    const [nombre, setNombre] = useState('');
    const [rut, setRut] = useState('');
    const [telefono, setTelefono] = useState('');
    const [calcularHabilitado, setCalcularHabilitado] = useState(false);
    const [isDatosEntregaCompleto, setIsDatosEntregaCompleto] = useState(false);
    const [rutValido, setRutValido] = useState(false); // Estado para la validez del RUT
    const [telefonoValido, setTelefonoValido] = useState(false); // Estado para la validez del RUT

    useEffect(() => {
        const getRegiones = async () => {
            try {
                const response = await axios.get('https://pruebas.onloop.cl/api/regiones.php');
                if (response.data.success) {
                    setRegiones(response.data.data);
                    // console.log("Regiones obtenidas:", response.data.data);
                } else {
                    console.error("Error al obtener las regiones:", response.data.msg);
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
            }
        };

        getRegiones();
    }, []);

    useEffect(() => {
        const getMediosPago = async () => {
            try {
                const response = await axios.post('https://pruebas.onloop.cl/api/medios_pago.php', {
                    funcion: 'select',
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setMediosPago(response.data.data.rows);
            } catch (error) {
                console.error("Error al obtener los medios de pago:", error);
            }
        };

        getMediosPago();
    }, []);

    useEffect(() => {
        const isComplete = nombre && rut && telefono && correo && direccion && rutValido && telefonoValido; 
        setIsDatosEntregaCompleto(isComplete);
    }, [nombre, rut, telefono, correo, direccion, rutValido, telefonoValido]);
    

    const totalSteps = () => steps.length;
    const completedSteps = () => Object.keys(completed).length;
    const isLastStep = () => activeStep === totalSteps() - 1;
    const allStepsCompleted = () => completedSteps() === totalSteps();

    const handleNext = async () => {
        if (requestSent) return;
        setRequestSent(true);
    
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);

        if (activeStep === 1) {
            if (!calcularHabilitado) {
                setRequestSent(false);
                return;
            }
    
            const precioEnvio = parseInt(comunaData.costoEnvio, 10) || 0; 
            const totalCompra = typeof precioTotal === 'function' ? parseInt(precioTotal(), 10) : 0;
            
            const productos = carritoFiltrado().map(producto => {
                let costeAdicionalModificado = 0;

                if (Number(comunaData.selectedRegion.id) === 7) {
                    costeAdicionalModificado = producto.coste_adicional;
    
                    if (costeAdicionalModificado !== 0) {
                        if (producto.cantidad < 2 && costeAdicionalModificado === 2000) {
                            costeAdicionalModificado = 0;
                        } else if (producto.cantidad >= 2) {
                            costeAdicionalModificado = (producto.cantidad - 1) * 2000;
                        }
                    }
                }
        
                return {
                    id_producto: producto.id,
                    titulo: producto.titulo,
                    precio: producto.precio,
                    cantidad: producto.cantidad,
                    coste_adicional: costeAdicionalModificado
                };
            });

            // Sumar los costes adicionales de todos los productos
            const totalCosteAdicional = productos.reduce((total, producto) => total + producto.coste_adicional, 0);
            const totalAPagar = totalCompra + precioEnvio + totalCosteAdicional;

            // console.log('Datos para enviar:', {
            //     nombre,
            //     rut,
            //     telefono,
            //     correo,
            //     direccion,
            //     comuna: comunaData.selectedComuna,
            //     region: {
            //         id: comunaData.selectedRegion.id,
            //         nombre: comunaData.selectedRegion.nombre,
            //     },
            //     precio_total: totalCompra,
            //     precio_comuna: precioEnvio,
            //     total_pagar: totalAPagar,
            //     productos: productos,
            // });

            // Enviar los datos al servidor
            try {
                const response = await axios.post('https://pruebas.onloop.cl/api/insertar_compra.php', new URLSearchParams({
                    nombre,
                    rut,
                    telefono,
                    correo,
                    precio_total: totalCompra,
                    precio_comuna: precioEnvio,
                    total_pagar: totalAPagar,
                    productos: JSON.stringify(productos), 
                    update_stock: false 
                }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
    
                if (response.data.success) {
                    // console.log('Compra registrada con éxito:', response.data);
                } else {
                    console.error('Error al registrar la compra:', response.data.error);
                }
            } catch (error) {
                console.error('Error al hacer la solicitud:', error);
            }
        }
    
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    
        setRequestSent(false);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        vaciarCarrito();
    };

    const handleEliminarProducto = (idProducto) => {
        eliminarProducto(idProducto);
        setComunaData(prevData => ({
            ...prevData,
            selectedComuna: '',
            costoEnvio: null
        }));
    };

    const handleDireccionChange = (event) => {
        setDireccion(event.target.value);
    };

    const handleCorreoChange = (event) => {
        setCorreo(event.target.value);
    };

    const handleNombreChange = (event) => {
        setNombre(event.target.value);
    };

    const handleRutChange = (event) => {
        setRut(event.target.value);
    };

    const handleTelefonoChange = (event) => {
        setTelefono(event.target.value);
    };

    const handleMedioPagoChange = (event) => {
        setMedioPago(event.target.value);
    };

    return (
        <Box sx={{ width: '100%', margin: '2rem 0' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <SeleccionMedioPago
                        medioPago={medioPago}
                        mediosPago={mediosPago}
                        handleMedioPagoChange={handleMedioPagoChange}
                        handleReset={handleReset}
                        carrito={carritoFiltrado()}
                        precioTotal={precioTotal}
                        costoEnvio={comunaData.costoEnvio}
                        nombre={nombre}
                        rut={rut}
                        telefono={telefono}
                        correo={correo}
                        direccion={direccion}
                        selectedComuna={comunaData.selectedComuna}
                        selectedRegion={comunaData.selectedRegion}
                    />
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1, py: 1 }}></Typography>
                        <div className="container">
                            {activeStep === 0 && (
                                <ConfirmarProductos 
                                    carrito={carritoFiltrado()} 
                                    precioTotal={precioTotal} 
                                    handleEliminarProducto={handleEliminarProducto} 
                                    selectedComuna={comunaData.selectedComuna}
                                    selectedRegion={comunaData.selectedRegion}
                                    setComunaData={setComunaData}
                                    comunaPrecio={comunaData.costoEnvio}
                                    precioVisible={true}
                                    setCalcularHabilitado={setCalcularHabilitado} // <-- Aquí
                                />
                            )}
                            {activeStep === 1 && (
                                <DatosEntrega 
                                    direccion={direccion}
                                    correo={correo}
                                    nombre={nombre}
                                    rut={rut}
                                    telefono={telefono}
                                    handleDireccionChange={handleDireccionChange}
                                    handleCorreoChange={handleCorreoChange}
                                    handleNombreChange={handleNombreChange}
                                    handleRutChange={handleRutChange}
                                    handleTelefonoChange={handleTelefonoChange}
                                    handleRutValidoChange={setRutValido}
                                    handleTelefonoValidoChange={setTelefonoValido}
                                />
                            )}
                            {activeStep === 2 && (
                                <Resumen 
                                    carrito={carritoFiltrado()} 
                                    precioTotal={precioTotal} 
                                    costoEnvio={comunaData.costoEnvio}
                                    direccion={direccion}
                                    nombre={nombre}
                                    rut={rut}
                                    correo={correo}
                                    telefono={telefono}
                                    selectedComuna={comunaData.selectedComuna}
                                    selectedRegion={comunaData.selectedRegion.nombre}
                                    selectedRegionId={comunaData.selectedRegion.id}
                                    totalAPagar={parseInt(precioTotal(), 10) + parseInt(comunaData.costoEnvio || 0, 10)}
                                />
                            )}
                        </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={(activeStep === 0) || carritoFiltrado().length === 0}
                                    onClick={handleBack}
                                    sx={{ ml: 4 , fontFamily: 'Outfit, sans-serif', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word' }}  /* Mover un poco hacia la derecha */
                                >
                                    Atrás
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button
                                    onClick={handleNext}
                                    disabled={activeStep === 1 ? (!calcularHabilitado || !isDatosEntregaCompleto) : !calcularHabilitado}
                                    sx={{ mr: 4 , fontFamily: 'Outfit, sans-serif', fontWeight: 400, textAlign: 'justify', textJustify: 'inter-word' }}  /* Mover un poco hacia la izquierda */
                                >
                                    {isLastStep() ? 'Finalizar' : 'Siguiente'}
                                </Button>
                            </Box>
                    </React.Fragment>
                )}
            </div>
        </Box>
    );
};

export default ConfirmacionCompra;
